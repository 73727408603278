.nm-home {

  //padding-top: 13vh; do not use this when column is the nm-home
  height: 94vh;
  background-color: $dark-theme-background-color;

  .home-code {
    color: $home-code-color;
    font-size: 2rem;
  }

  .nm-greeting, .nm-interest, .nm-intro {
    font-size: 4rem;
    color: white;
  }

  .nm-name {
    color: $name-color;
  }

  .nm-opening-tag, .nm-closing-tag, .nm-btn {
    max-width: 300px;
  }

  .nm-opening-brace, .nm-closing-brace {
    max-width: 600px;
  }

  .nm-section {
    max-width: 900px;
  }

  .nm-btn {
    .btn {
      background-color: transparent;
      border-radius: 35px;
      border: 1px solid $work-btn-border-color;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 992px){

    //padding-top: 15vh;

    .home-code {
      font-size: 1.5rem;
    }

    .nm-greeting, .nm-interest, .nm-intro {
      font-size: 3rem;
    }

    .nm-opening-tag, .nm-closing-tag, .nm-btn {
      max-width: 256px;
    }

    .nm-opening-brace, .nm-closing-brace {
      max-width: 512px;
    }

    .nm-section {
      max-width: 768px;
    }

    .nm-btn {
      .btn {
        font-size: 1.2rem;
      }
    }

  }

  @media screen and (max-width: 768px){

    //padding-top: 20vh;

    .home-code {
      font-size: 1.2rem;
    }

    .nm-greeting, .nm-interest, .nm-intro {
      font-size: 2.5rem;
    }

    .nm-opening-tag, .nm-closing-tag, .nm-btn {
      max-width: 200px;
    }

    .nm-opening-brace, .nm-closing-brace {
      max-width: 400px;
    }

    .nm-section {
      max-width: 600px;
    }

    .nm-btn {
      .btn {
        font-size: 1.2rem;
      }
    }

  }

  @media screen and (max-width: 512px){

    //padding-top: 14vh;

    .home-code {
      font-size: 1.2rem;
    }

    .nm-greeting, .nm-interest, .nm-intro {
      font-size: 2.5rem;
    }

    .nm-opening-tag, .nm-closing-tag, .nm-btn {
      max-width: 200px;
    }

    .nm-opening-brace, .nm-closing-brace {
      max-width: 400px;
    }

    .nm-section {
      max-width: 600px;
    }

    .nm-btn {
      .btn {
        font-size: 1.2rem;
      }
    }

  }

  @media screen and (max-width: 360px){

    //padding-top: 14vh;

    .home-code {
      font-size: 1rem;
    }

    .nm-greeting, .nm-interest, .nm-intro {
      font-size: 2rem;
    }

    .nm-opening-tag, .nm-closing-tag, .nm-btn {
      max-width: 200px;
    }

    .nm-opening-brace, .nm-closing-brace {
      max-width: 400px;
    }

    .nm-section {
      max-width: 600px;
    }

    .nm-btn {
      .btn {
        font-size: 1rem;
      }
    }

  }

}