.pd-anim {
  animation: right_to_left 3s ease-in;

}

@keyframes right_to_left {
  from {
    position: absolute;
    margin-left: 100%;
  }
  to {
    position: inherit;
    margin-left: 0;
  }
}
