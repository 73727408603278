.connect-me-row {

  background-color: $dark-theme-background-color;
  height: 10vh;
  max-height: 10vh;

  .connect-me-col {

    .connect-me-text {
      color: white;
    }

    .connect-me-line {
      position: relative;
      margin-left: 50px;
      margin-right: 50px;
      width: 8%;
      height: 1px;
      display: inline-flex;
      background-color: $greeting-text-color;
      border-radius: 10px;
    }

    .icon-container {
      position: absolute;
      display: inline-flex;
    }

    .connect-me-icon {
      font-size: 1.5rem;
      transition: font-size 0.2s ease-in-out;
    }

    .connect-me-icon:hover {
      cursor: pointer;
      font-size: 2rem;
      transition: font-size 0.2s ease-in-out;
    }

  }

}

@media screen and (max-width: 575px) {

  .connect-me-row {

    .connect-me-col {

      .connect-me-text {
        font-size: 0.9rem;
      }

      .connect-me-line {
        margin-left: 30px;
        margin-right: 30px;
        width: 15%;
        display: inline-flex;
      }

      .connect-me-icon {
        font-size: 1.2rem;
        transition: font-size 0.2s ease-in-out;
      }

      .connect-me-icon:hover {
        font-size: 1.5rem;
        transition: font-size 0.2s ease-in-out;
      }

    }

  }


}

@media screen and (max-width: 400px) {

  .connect-me-row {

    .connect-me-col {

      .connect-me-text {
        font-size: 0.8rem;
      }

      .connect-me-line {
        margin-left: 25px;
        margin-right: 25px;
        width: 15%;
        display: inline-flex;
      }

      .connect-me-icon {
        font-size: 1rem;
        transition: font-size 0.2s ease-in-out;
      }

      .connect-me-icon:hover {
        font-size: 1.3rem;
        transition: font-size 0.2s ease-in-out;
      }

    }

  }


}

@media screen and (max-width: 350px) {

  .connect-me-row {
    .connect-me-col {

      padding-left: 15px !important;
      .connect-me-text {
        font-size: 0.8rem;
      }

      .connect-me-line {
        margin-left: 15px;
        margin-right: 15px;
        width: 15%;
        display: inline-flex;
      }

      .connect-me-icon {
        font-size: 1em;
        transition: font-size 0.2s ease-in-out;
      }

      .connect-me-icon:hover {
        font-size: 1.3rem;
        transition: font-size 0.2s ease-in-out;
      }

    }

  }


}