$dark-theme-background-color: #1d1d1d;
$home-code-color: #4a4a4b;
$name-color: #db00ff;
$work-btn-border-color: #00cbfd;
$about-graduation-color: #a1a1a1;
$greeting-hand-color: #ffd556;
$greeting-text-color: #5a5a5a;
$gray-work-area-background-color: #393939;

$tech-skill-color1: #cae3e8;
$tech-skill-color2: #a8d1d5;
$tech-skill-color3: #9ac3c5;
$tech-skill-color4: #87a6aa;
$tech-skill-color5: #81a4a8;
$tech-skill-color6: #668286;
$tech-skill-color7: #768c90;
$tech-skill-color8: #577374;
$tech-skill-color9: #3d5053;
$tech-skill-color10: #3a4b4f;

$tech-skill-color11: #594d49;
$tech-skill-color12: #61544d;
$tech-skill-color13: #554944;
$tech-skill-color14: #52433e;
$tech-skill-color15: #4d403a;
$tech-skill-color16: #453b35;
$tech-skill-color17: #443a34;
$tech-skill-color18: #3e3632;
$tech-skill-color19: #332e2a;
$tech-skill-color20: #393430;