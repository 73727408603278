.navigation-row {

  height: 6vh;

  .nav-line-1 {
    position: absolute;
    height: 1px;
    display: inline-flex;
    background-color: $greeting-text-color;
    border-radius: 10px;
    z-index: 1;
  }

  .nav-line-2 {
    position: absolute;
    height: 1px;
    display: inline-flex;
    background-color: $greeting-text-color;
    border-radius: 10px;
    z-index: 1;
  }

  .icon-dot {
    cursor: pointer;
    position: relative;
    color: white;
    z-index: 2;
  }

  .active-icon-dot {
    cursor: pointer;
    position: relative;
    color: $work-btn-border-color;
    z-index: 2;
  }

}