@import "~bootstrap/scss/bootstrap";
@import "values/variables";
@import "themes/theme";
@import "nm_home";
@import "nm_nav";
@import "nm_flipping";
@import "nm_about";
@import "nm_tech_stack";
@import "nm_contact";
@import "nm_details";
@import "nm_connect";
@import "nm_work";
@import "nm_project_nav";
@import "nm_project_image";
@import "nm_project_description";
@import "nm_project_description_anim";
@import "nm_notice";