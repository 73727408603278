.nm-nav-bar {

  height: 6vh;
  background-color: $dark-theme-background-color;

  .nm-logo {
    width: auto;
    height: 4vh;
    object-fit: contain;
    cursor: pointer;
  }

  .navbar-collapse {
    max-width: 1150px !important;

    .nav-link {
      color: white;
      letter-spacing: 3px;
      padding: 0;
      box-sizing: border-box;
      position: relative;
      z-index: 12;

      .hover-line {
        position: absolute;
        width: 66%;
        height: 4px;
        margin-top: 5px;
        background-color: transparent;
        transition: background-color 0.4s ease-in-out;
      }

      .active-hover-line {
        position: absolute;
        width: 66%;
        height: 4px;
        margin-top: 5px;
        display: flex;
        background-color: $name-color;
      }

      &:hover {
        .hover-line {
          display: flex;
          transition: background-color 0.4s ease-in-out;
          background-color: $name-color;
        }
      }

    }



  }

  @media screen and (max-width: 575px) {

    .navbar-collapse {
      .nav-link {
        font-size: 0.8rem;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 2px;

        .active-hover-line {
          display: none;
        }

        &:hover {
          .hover-line {
            display: none;
          }
          background-color: #4a4a4b;
          opacity: 0.5;
        }

      }
    }

  }

}