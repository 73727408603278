.nm-about {

  height: 94vh; //temporal
  background-color: $dark-theme-background-color;

  .about-col {

    max-width: 1600px;

    .row-1-personal {

      height: 70vh;

      .col-1-text {

        .nm-intro {
          font-size: 3.3rem;
          font-weight: 800;
        }

        .nm-name {
          color: $name-color;
        }

        .inner-row {

          color: $about-graduation-color;

          h5 {
            font-weight: 800;
            letter-spacing: 1px;
          }

        }

      }

      .col-2-img {
        .nm-image {
          width: auto;
          height: 80vh;
          object-fit: contain;
        }
      }


    }

    .nm-btn {

      .btn {
        background-color: transparent;
        border-radius: 35px;
        border: 1px solid $work-btn-border-color;
        font-size: 1.4rem;
      }

    }


  }

}

@media screen and (max-width: 1420px) {

  .nm-about {

    .about-col {

      //max-width: 1600px;

      .row-1-personal {

        //height: 70vh;

        .col-1-text {

          .nm-intro {
            font-size: 2.3rem;
            font-weight: 800;
          }

          .inner-row {

            h5 {
              font-weight: 800;
              font-size: 1rem;
              letter-spacing: 1px;
            }

          }

        }

        .col-2-img {
          .nm-image {
            width: auto;
            height: 35vh;
            object-fit: contain;
          }
        }


      }

      .nm-btn {
        .btn {
          font-size: 1.2rem;
        }
      }

    }

  }

}

@media screen and (max-width: 886px) {

  .nm-about {

    .about-col {

      //max-width: 1600px;

      .row-1-personal {

        //height: 70vh;

        .col-1-text {

          .nm-intro {
            font-size: 2rem;
            font-weight: 800;
          }

          .inner-row {

            h5 {
              font-weight: 800;
              font-size: 0.8rem;
              letter-spacing: 1px;
            }

          }

        }

        .col-2-img {
          .nm-image {
            width: auto;
            height: 30vh;
            object-fit: contain;
          }
        }


      }

      .nm-btn {
        .btn {
          font-size: 1.2rem;
        }
      }

    }

  }

}

@media screen and (max-width: 593px) {

  .nm-about {

    .about-col {

      margin-top: auto;
      margin-bottom: auto;

      .row-1-personal {

        //height: 70vh;

        .col-1-text {

          .nm-intro {
            font-size: 1.5rem;
            font-weight: 800;
          }

          .inner-row {

            h5 {
              font-weight: 800;
              font-size: 0.8rem;
              letter-spacing: 1px;
            }

          }

        }

        .col-2-img {
          .nm-image {
            width: auto;
            height: 30vh;
            object-fit: contain;
          }
        }


      }

      .row-1-personal :nth-child(1) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
      }

      .row-1-personal :nth-child(2) {
        max-width: 100%;
        flex: 0 0 100%;
        order: 1;
      }

      .nm-btn {
        .btn {
          font-size: 1rem;
        }
      }

    }

  }

}