/*font-size: 1rem;
        transform-style: preserve-3d;
        data-shadow: -1px 1px 0 $tech-skill-color11,
        -2px 2px 0 $tech-skill-color12,
        -3px 3px 0 $tech-skill-color13,
        -4px 4px 0 $tech-skill-color14,
        -5px 5px 0 $tech-skill-color15,
        -6px 6px 0 $tech-skill-color16,
        -7px 7px 0 $tech-skill-color17,
        -8px 8px 0 $tech-skill-color18,
        -9px 9px 0 $tech-skill-color19,
        -10px 10px 0 $tech-skill-color20;
        letter-spacing: 5px;
*/

.nm-tech-stack {

  background-color: $dark-theme-background-color;
  height: 94vh;

  .nm-tech-stack-col {

    .col {
      div {
        transition: 0.9s;
      }
    }

    span {
      color: yellow;
      text-shadow: -1px 1px 0 $tech-skill-color11,
      -2px 2px 0 $tech-skill-color12,
      -3px 3px 0 $tech-skill-color13,
      -4px 4px 0 $tech-skill-color14,
      -5px 5px 0 $tech-skill-color15,
      -6px 6px 0 $tech-skill-color16,
      -7px 7px 0 $tech-skill-color17,
      -8px 8px 0 $tech-skill-color18,
      -9px 9px 0 $tech-skill-color19,
      -10px 10px 0 $tech-skill-color20;
      letter-spacing: 5px;
    }

  }

}