.nm-work-row {
  background-color: $dark-theme-background-color;
  height: 94vh;
}

.project-area-row {

  width: 95%;

  .project-area-col {

  }

}

.project-button-row {


  .btn {
    background-color: transparent;
    border: none;
    border-radius: 35px;
    font-size: 1rem;
  }

}

@media screen and (max-width: 992px) {

  .project-button-row {

    .btn {
      font-size: 0.9rem;
    }

  }

}
@media screen and (max-width: 768px) {
  .project-button-row {

    margin-top: 75px !important;

    .btn {
      font-size: 0.8rem;
    }

  }
}
@media screen and (max-width: 576px) {}
@media screen and (max-width: 420px) {
  .project-button-row {

    margin-top: 25px !important;


    .btn {
      font-size: 0.6rem;
    }

  }
}

@media screen and (max-width: 350px) {
  .project-button-row {

    margin-top: 45px !important;


    .btn {
      font-size: 0.6rem;
    }

  }
}
