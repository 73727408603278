.contact-me-row {

  background-color: $dark-theme-background-color;
  height: 84vh;

  .contact-me-col {

    max-width: 1300px;
    height: 60vh;

    .form-contact-col {

      .greeting-hand {
        color: $greeting-hand-color;
        transform: rotateZ(-45deg);
      }

      .greeting-text {
        color: $greeting-text-color;
      }

      .form-label-active {
        color: $greeting-text-color;
      }

      //basic input style
      .form-control {
        background-color: inherit;
        border: none;
        caret-color: white;
        color: white;
        transition: font-size 1s ease-in-out;
        font-size: 1rem;
      }

      .form-control:not(:focus) {
        border-bottom: 1px solid $greeting-text-color;
        border-radius: 10px;
        transition: 1s ease-in-out;
      }

      .form-control:focus {
        border-bottom: 1px solid transparent;
        border-radius: 10px;
        transition: 1s ease-in-out;
      }

      .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $greeting-text-color;
        opacity: 1; /* Firefox */
      }

      .form-control::-ms-input-placeholder { /* Microsoft Edge */
        color: $greeting-text-color;
      }

      //active border color and etc overriding bootstrap
      textarea:focus,
      textarea.form-control:focus,
      input.form-control:focus,
      input[type=text]:focus,
      input[type=password]:focus,
      input[type=email]:focus,
      input[type=number]:focus,
      [type=text].form-control:focus,
      [type=email].form-control:focus,
      [contenteditable].form-control:focus {
        box-shadow: inset 0 -1px 0 $dark-theme-background-color;
        font-size: 2rem;
      }

      button.btn:active,
      button.btn:focus {
        box-shadow: inset 0 -1px 0 $dark-theme-background-color;
      }

      //auto fill input background
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active
      {
        -webkit-background-clip: text;
        -webkit-box-shadow: 0 0 0 30px $dark-theme-background-color inset !important;
        -webkit-text-fill-color: white !important;
        -webkit-background-size: 0;
        -webkit-border-radius: 10px;
      }

      //increase autofill value font-size when selecting the value
      input:-webkit-autofill:focus::first-line
      {
        font-size: 2rem;
        font-family: 'Roboto', sans-serif !important;
      }

      //decrease autofill value font-size when no focus
      input:-webkit-autofill:not(:focus)::first-line {
        font-size: 1rem;
      }

      .input-underline-active {
        width: 100%;
        height: 4px;
        background-color: white;
        border-radius: 10px;
        transition: 1s ease-in-out;
      }

      .input-underline {

        width: 100%;
        height: 4px;
        background-color: transparent;
        border-radius: 10px;
        transition: 1s ease-in-out;

      }

      .btn {
        background-color: unset !important;
        border: none;
        color: $greeting-text-color;
        letter-spacing: 1px;
        position: relative;

        .btn-underline {
          width: 100%;
          height: 1px;
          background-color: $greeting-text-color;
          border-radius: 10px;
        }

      }

    }

  }


}

@media screen and (max-width: 1450px) {

  .contact-me-row {

    .contact-me-col {

      max-width: 1000px;


    }


  }


}

@media screen and (max-width: 1140px) {

  .contact-me-row {

    .contact-me-col {

      max-width: 900px;


    }


  }


}

@media screen and (max-width: 1030px) {

  .contact-me-row {

    .contact-me-col {

      max-width: 700px;


    }


  }


}

@media screen and (max-width: 767px) {

  .contact-me-row {

    height: 100%;

    .contact-me-col {

      height: 100%;

      padding-top: 50px;
      padding-right: 50px;
      padding-left: 50px;

      max-width: 700px;

      :nth-child(1) {
        order: 2;
      }

      :nth-child(2) {
        order: 1;
      }

      .form-contact-col {

        padding-top: 50px;

        //basic input style
        .form-control {
          font-size: 1rem;
        }

        //active border color and etc overriding bootstrap
        textarea:focus,
        textarea.form-control:focus,
        input.form-control:focus,
        input[type=text]:focus,
        input[type=password]:focus,
        input[type=email]:focus,
        input[type=number]:focus,
        [type=text].form-control:focus,
        [type=email].form-control:focus,
        [contenteditable].form-control:focus {
          box-shadow: inset 0 -1px 0 $dark-theme-background-color;
          font-size: 2rem;
        }

        //auto fill input background
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active
        {
          -webkit-background-clip: text;
          -webkit-box-shadow: 0 0 0 30px $dark-theme-background-color inset !important;
          -webkit-text-fill-color: white !important;
          -webkit-background-size: 0;
          -webkit-border-radius: 10px;
        }

        //increase autofill value font-size when selecting the value
        input:-webkit-autofill:focus::first-line
        {
          font-size: 2rem;
          font-family: 'Roboto', sans-serif !important;
        }

        //decrease autofill value font-size when no focus
        input:-webkit-autofill:not(:focus)::first-line {
          font-size: 1rem;
        }

        .btn {
          letter-spacing: 1px;
        }

      }

    }

  }


}