/* The flip card container -
  set the width and height to whatever you want.
  We have added the border property to demonstrate that the flip itself goes out of the box on hover
  (remove perspective if you don't want the 3D effect
*/

.nm-flip-container {
  //perspective: 1000px; /* Remove this if you don't want the 3D effect */

}

.nm-home {
  //transform-style: preserve-3d;
  animation: flipping_anime 1.5s ease-in-out;
}

.nm-about {
  animation: flipping_anime 1.5s ease-in-out;
  //transform-style: preserve-3d;
}

.nm-tech-stack {
  animation: flipping_anime 1.5s ease-in-out;
  //transform-style: preserve-3d;
}

.nm-contact {
  animation: flipping_anime 1.5s ease-in-out;
  //transform-style: preserve-3d;
}

.nm-work {
  animation: flipping_anime 1.5s ease-in-out;
  //perspective: 1000px;
}

@keyframes flipping_anime {
  0% {
    transform: scale3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
