.contact-me-row {

  .contact-me-col {

    .details-col {

      h6.nm-detail-title {
        color: $greeting-text-color;
      }

      h6 {
        color: white;
      }

      .eq-height-container {

        display: grid;
        grid-auto-rows: 1fr;
        height: 100%;
        float: right;

      }

      .eq-height-row {
        grid-column: 1;
      }

      .middle-content {
        display: flex;
        align-items: center;
      }

      .end-content {
        display: flex;
        align-items: flex-end;
      }


    }

  }
}

@media screen and (max-width: 767px) {

  .contact-me-col {

    .details-col {

      .eq-height-container {
        padding-top: 50px;
        float: unset !important;

        :nth-child(1) {
          order: 1;
        }

        :nth-child(2) {
          order: 2;
        }

        :nth-child(3) {
          order: 3;
        }

      }

      .middle-content {
        display: flex;
        align-items: center;
      }

      .end-content {
        display: flex;
        align-items: center;
      }


    }

  }

}