.project-demo-link {
  background-color: transparent;
  position: relative;
  color: $work-btn-border-color;
  font-size: 1.4rem;

  &:hover {
    color: $work-btn-border-color;
    cursor: pointer;
    text-decoration: none;
  }

}

.external-link-icon {
  position: absolute;
  font-size: 0.8rem;
  color: $work-btn-border-color;

  &:hover {
    cursor: pointer;
  }
}

.not-hosted {
  color: $work-btn-border-color;
}

.project-description {

  .eq-height-container {

    display: grid;
    grid-auto-rows: 1fr;
    height: 100%;
    float: right;

  }

  .eq-height-row {
    grid-column: 1;
  }

  .middle-content {
    display: flex;
    align-items: center;
  }

  .end-content {
    display: flex;
    align-items: flex-end;
  }

  h6, h4 {
    color: white;
  }

  h6 {
    letter-spacing: 0.2rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }


}

@media screen and (max-width: 1600px) {

  .project-demo-link {
    font-size: 1.4rem;

  }

  .external-link-icon {
    font-size: 0.8rem;

  }

  .project-description {

    .eq-height-container {

      display: grid;
      grid-auto-rows: minmax(20%, auto);
      height: 100%;
      float: right;

    }

    h4 {
      font-size: 1.3rem;
    }

    h6 {
      letter-spacing: 0.2rem;
      line-height: 1.5rem;
      font-size: 0.9rem;
    }


  }


}

@media screen and (max-width: 1400px) {

  .project-demo-link {
    font-size: 1.2rem;

  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {

    h4 {
      font-size: 1.3rem;
    }

    h6 {
      letter-spacing: 0.1rem;
      line-height: 1.5rem;
      font-size: 0.8rem;
    }


  }


}

@media screen and (max-width: 1000px) {

  .project-demo-link {
    font-size: 1.2rem;

  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {


    h4 {
      font-size: 1.2rem;
    }

    h6 {
      letter-spacing: 0;
      line-height: 1.5rem;
      font-size: 0.8rem;
    }


  }


}

@media screen and (max-width: 800px) {

  .project-demo-link {
    font-size: 1.2rem;

  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .not-hosted {
    font-size: 0.9rem;
  }

  .project-description {


    h4 {
      font-size: 1.2rem;
    }

    h6 {
      letter-spacing: 0.1rem;
      line-height: 1.5rem;
      font-size: 0.7rem;
    }

  }


}

@media screen and (max-width: 768px) {

  .project-demo-link {
    font-size: 1.2rem;

  }

  .not-hosted {
    font-size: 0.9rem;
  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {


    .md-end-content {
      display: flex;
      align-items: flex-end;
    }


    h4 {
      font-size: 1.1rem;
    }

    h6 {
      letter-spacing: 0.1rem;
      line-height: 1.5rem;
      font-size: 0.7rem;
    }

  }


}

@media screen and (max-width: 600px) {

  .project-demo-link {
    font-size: 1.2rem;
  }

  .not-hosted {
    font-size: 0.9rem;
  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {


    .md-end-content {
      display: flex;
      align-items: flex-end;
    }


    h4 {
      font-size: 1.1rem;
    }

    h6 {
      letter-spacing: 0.1rem;
      line-height: 1.3rem;
      font-size: 0.7rem;
    }

  }


}

@media screen and (max-width: 450px) {

  .project-demo-link {
    font-size: 0.9rem;
  }

  .not-hosted {
    font-size: 0.9rem;
  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {


    .xs-middle-content {
      display: flex;
      align-items: center;
    }


    h4 {
      font-size: 1rem;
    }

    h6 {
      letter-spacing: 0;
      line-height: 1rem;
      font-size: 0.7rem;
    }

  }


}

@media screen and (max-width: 370px) {

  .project-demo-link {
    font-size: 0.8rem;
  }

  .not-hosted {
    font-size: 0.8rem;
  }

  .external-link-icon {
    font-size: 0.8rem;
  }

  .project-description {


    .xs-middle-content {
      display: flex;
      align-items: center;
    }

    .xs-end-content {
      display: flex;
      align-items: flex-end;
    }


    h4 {
      font-size: 0.8rem;
    }

    h6 {
      letter-spacing: 0;
      line-height: 0.9rem;
      font-size: 0.5rem;
    }

  }


}




