.content-row {

  height: 70vh;

  .image-container {
    position: relative;
  }

  .image-shadow {
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    will-change: filter;
    filter: blur(10.2794px);
    transform: rotateX(50deg) rotateZ(-30deg) rotateY(10deg) scale3d(1, 1, 1);
    top: 30px;
    transition: 1s ease-in-out;
    transform-style: preserve-3d;
  }

  .image-container {
    &:hover {
      .image-shadow {
        transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-80px) translateX(-20px);
      }

      .image {
        transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-50px);
      }
    }
  }

  .image {

    will-change: transform;
    transform: rotateX(50deg) rotateZ(-30deg) rotateY(10deg) scale3d(1, 1, 1);
    transform-style: preserve-3d;
    transition: 1s ease-in-out;
  }

}

@media screen and (max-width: 767px) {

  .content-row {

    .image-shadow {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      top: 30px;
    }

    .image-container {
      &:hover {
        .image-shadow {
          transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-10px);
        }

        .image {
          transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-10px);
        }
      }
    }

    .image {
      width: 85%;
    }
  }



}

@media screen and (max-width: 575px) {

  .content-row {

    .image-shadow {
      left: 10px;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      top: 20px;
    }

    .image-container {
      &:hover {
        .image-shadow {
          transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-10px);
        }

        .image {
          transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale3d(0.8, 0.8, 1) translateY(-10px);
        }
      }
    }

    .image {
      width: 95%;
    }
  }



}
